@import url("https://use.typekit.net/gtn8isd.css");
@import "base";

:root {
  --jelly-bean: #21768f;
  --dark: #2d2d48;
  --dusk: #575775;
  --gunmetal: #545960;
  --perrywinkle: #9595c9;
  --white: #ffffff;
  --tealish: #2fa7ca;
  --cloudy-blue: #b7b7cd;
  --bluey-grey: #8686af;
  --catskill-white: #f0f4f9;
}

body {
  font-family: "proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 400;
  text-align: center;
  background-color: var(--dark);
}

a {
  transition: color 300ms ease;

  &:hover,
  &:active {
    color: var(--jelly-bean);
  }
}

h1,
h2,
p {
  font-family: cardea, serif;
  font-weight: 400;
}

a,
h3,
h4,
h5 {
  font-weight: 700;
}

a,
h3 {
  color: var(--tealish);
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
}

/* HEADER */

header {
  height: 284px;
  background-color: var(--catskill-white);
}

h1 {
  font-size: 24px;
  margin-bottom: 15px;
  color: var(--dark);
}

h2 {
  font-size: 18px;
  line-height: 1.44;
  max-width: 230px;
  color: var(--dusk);
}

h3 {
  margin: 60px 0 15px;
}

h4 {
  margin-bottom: 15px;
  color: var(--perrywinkle);
}

h3,
h4 {
  font-size: 14px;
  text-transform: uppercase;
}

.logo {
  font-size: 16px;
  align-self: flex-start;
}

/* MAIN */

main {
  height: 315px;
  background: var(--white);

  .container {
    padding: 30px 20px;
    justify-content: space-around;
  }

  p {
    font-size: 16px;
    color: var(--gunmetal);
  }
}

img {
  // margin-bottom: 20px;
}

/* FOOTER */

footer {
  padding: 40px 20px;
}

h5 {
  font-size: 14px;
  color: var(--cloudy-blue);
}

p {
  font-size: 16px;
  line-height: 1.6;
  color: var(--white);
}

ul {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;
  margin: 40px 0;
}

small {
  font-size: 12px;
  margin-top: auto;
  color: var(--bluey-grey);
}

@import "index.tablet";
@import "index.md";
@import "index.hd";
