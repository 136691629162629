@media (min-width: 768px) {
  /* HEADER */
  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 22px;
    max-width: 300px;
  }

  h3 {
    font-size: 18px;
    margin-top: 30px;
  }

  h4 {
    margin-bottom: 15px;
    color: var(--perrywinkle);
    font-size: 16px;
  }

  .logo {
    font-size: 18px;
  }

  /* MAIN */
  main {
    height: 345px;

    p {
      font-size: 20px;
      max-width: 450px;
    }
  }

  img {
    width: 160px;
  }

  /* FOOTER */

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 20px;
    max-width: 350px;
  }

  ul {
    flex-direction: row;
    width: 80%;
    height: auto;
    margin: 60px 0;
    font-size: 16px;
  }

  small {
    font-size: 14px;
  }
}
