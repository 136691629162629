@media (min-width: 1440px) {
  .container {
    max-width: 1220px;
  }

  /* HEADER */
  header {
    height: 700px;
    padding-top: 50px;
  }

  h1 {
    font-size: 50px;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 26px;
    line-height: 1.48;
    margin-top: 70px;
  }

  h4 {
    color: var(--perrywinkle);
  }

  .logo {
    font-size: 22px;
  }

  /* MAIN */
  main {
    width: 1200px;
    height: 400px;
    margin: -160px auto 0;

    p {
      font-size: 26px;
      line-height: 1.62;
    }
  }

  img {
    width: 280px;
  }

  /* FOOTER */

  footer {
    padding-top: 60px;
  }

  h5 {
    font-size: 20px;
  }

  p {
    font-size: 26px;
    line-height: 1.48;
  }

  ul {
    margin: 120px 0;
    font-size: 20px;
  }

  small {
    font-size: 15px;
  }

}
