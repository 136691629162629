*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  box-sizing: border-box;
}

body {
  margin: 0;
}

main {
  display: block;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: inherit;
}

strong {
  font-weight: bolder;
}

img {
  border-style: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: inherit;
  font-weight: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
