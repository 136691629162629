@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    margin: 0 auto;
  }

  /* HEADER */
  header {
    height: 600px;
    padding-top: 40px;
  }

  h1 {
    font-size: 46px;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 24px;
    max-width: 400px;
  }

  h3 {
    font-size: 24px;
    line-height: 1.42;
    margin-top: 60px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 15px;
    color: var(--perrywinkle);
  }

  .logo {
    font-size: 20px;
  }

  /* MAIN */
  main {
    width: 1000px;
    height: 300px;
    margin: -140px auto 0;
    border: solid 1px #f0f5f8;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 0 20px 25px -12px rgba(0, 0, 0, 0.15);

    .container {
      flex-direction: row-reverse;
      justify-content: space-evenly;
    }

    p {
      font-size: 24px;
      line-height: 1.58;
      text-align: left;
    }
  }

  img {
    width: 250px;
  }

  /* FOOTER */

  h5 {
    font-size: 18px;
  }

  p {
    font-size: 24px;
    line-height: 1.42;
    max-width: 500px;
  }

  ul {
    margin: 100px 0;
    font-size: 18px;
  }

  small {
    font-size: 14px;
  }

}
